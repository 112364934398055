<template>
    <div class="w100">
        <div class="w100 bs rp_wrap">
            <div class="w100">
                <span class="rp_top_h text-18 text-bold">{{fromType=='edit'?'职位信息':'发布职位'}}</span>
            </div>
            <div class="rp_from bs">
                <el-form ref="ruleFormRef" :model="rpRuleFrom" :rules="rprules" class="demo-ruleForm"
                    style="margin-top:16px;" label-width="120px">
                    <el-form-item label="职位名称:" prop="pName" show-message>
                        <el-input v-model="rpRuleFrom.pName" type="text" placeholder="请输入职位名称" />
                    </el-form-item>

                    <el-form-item label="职位类别:" prop="pCategory">
                        <el-cascader placeholder="请选择职位类别" class="w100" :show-all-levels="false"
                            v-model="rpRuleFrom.pCategory" :options="categoryOp" filterable @change="categoryChange" />
                    </el-form-item>

                    <el-form-item label="职责描述:" prop="pDescribe">
                        <div class="w100 bs">
                            <div class="text-14 c_81868f">请勿输入邮箱、电话、链接，请不要包含性别歧视语</div>
                            <el-input v-model="rpRuleFrom.pDescribe" placeholder="请输入岗位职责、任职要求等描述" type="textarea"
                                :maxlength="10000" :rows="8" show-word-limit />
                        </div>
                    </el-form-item>

                    <el-form-item label="职位关键词:" prop="pKeyword">
                        <el-select v-model="rpRuleFrom.pKeyword" multiple filterable allow-create class="w100"
                            :reserve-keyword="false" placeholder="请选择职位关键词，有助于帮您更精准的匹配人才">
                            <el-option v-for="item in pKeyword" :key="item.value" :label="item.label"
                                :value="item.value" />
                        </el-select>
                    </el-form-item>

                    <el-form-item label="职位亮点:" prop="pHighlights">
                        <el-select v-model="rpRuleFrom.pHighlights" multiple filterable allow-create class="w100"
                            :reserve-keyword="false" placeholder="请选择职位亮点，提升职位竞争力">
                            <el-option v-for="item in pHighlights" :key="item.value" :label="item.label"
                                :value="item.value" />
                        </el-select>
                    </el-form-item>

                    <el-form-item label="职位性质:" prop="pNature">
                        <el-radio-group v-model="rpRuleFrom.pNature">
                            <el-radio :label="item.name" v-for="(item, index) in natureList" :key="index" />
                        </el-radio-group>
                    </el-form-item>

                    <div class="flex">
                        <el-form-item label="学历/经验:" prop="education" class="flex-sub">
                            <el-select v-model="rpRuleFrom.education" class="w100" placeholder="请选择学历要求">
                                <el-option v-for="item in education" :key="item.code" :label="item.name"
                                    :value="item.code" />
                            </el-select>
                        </el-form-item>
                        <el-form-item prop="experience" style="width:272px;margin-left: 16px;" label-width="auto">
                            <el-select v-model="rpRuleFrom.experience" class="w100" placeholder="请选择工作经验">
                                <el-option v-for="item in experience" :key="item.code" :label="item.name"
                                    :value="item.code" />
                            </el-select>
                        </el-form-item>
                    </div>


                    <div class="flex">
                        <el-form-item label="薪资范围:" prop="minS" class="flex-sub">
                            <el-select v-model="rpRuleFrom.minS" placeholder="最低" class="w100 select-prefix">
                                <el-option v-for="item in minSop" :key="item.value" :label="item.label"
                                    :value="item.value" />
                                <template #prefix>K</template>
                            </el-select>
                        </el-form-item>
                        <el-form-item prop="maxS" style="width:170px;margin-left: 16px;" label-width="auto">
                            <el-select v-model="rpRuleFrom.maxS" placeholder="最高" class="w100 select-prefix">
                                <el-option v-for="item in maxSop" :key="item.value" :label="item.label"
                                    :value="item.value" />
                                <template #prefix>K</template>
                            </el-select>
                        </el-form-item>
                        <el-form-item prop="salary" style="width:170px;margin-left: 16px;" label-width="auto">
                            <el-select v-model="rpRuleFrom.salary" placeholder="请选择薪资" class="w100 select-prefix">
                                <el-option v-for="item in salaryop" :key="item.value" :label="item.label"
                                    :value="item.value" />
                                <template #prefix>薪</template>
                            </el-select>
                        </el-form-item>
                    </div>
                    <el-form-item label="招聘人数" prop="persons">
                        <el-input v-model.number="rpRuleFrom.persons" type="number" placeholder="请输入招聘人数" />
                    </el-form-item>

                    <el-form-item label="工作地点:" prop="work">
                        <el-cascader placeholder="请选择工作城市" class="w100" :show-all-levels="false"
                            v-model="rpRuleFrom.work" :options="workop" :props="workProps" filterable />
                    </el-form-item>

                    <el-form-item>
                        <div class="w100 flex align-center" style="padding-top: 22px;">
                            <el-button type="primary" @click="comit(ruleFormRef)">{{fromType=='edit'?'保存':'发布职位'}}</el-button>
                        </div>
                    </el-form-item>
                </el-form>
            </div>
        </div>
        <div style="height: 24px;" class="w100"></div>
    </div>
</template>

<script lang="ts">
import { defineComponent, reactive, toRefs, ref } from 'vue';
import router from "@/router/index";
import { addHunterJob, listJobKeyword, listJobLight, listDict, listJobCategory, listCity, getHunterJob } from '@/util/apis'
import { ElLoading, ElMessage } from 'element-plus'
export default defineComponent({
    name: "",
    setup() {
        var loading: { close: () => void; } | null = null;
        const ruleFormRef = ref(null);
        const state = reactive({
            rpRuleFrom: {
                pName: '',//职位名称
                pCategory: '',//职位类别
                pDescribe: '',//职位描述
                pKeyword: [],//职位关键词
                pHighlights: [],//职位亮点
                pNature: '',//职位性质
                education: '',//学历
                experience: '',//经验
                salary: 12,//薪资
                minS: '',
                maxS: '',
                persons: '',//人数
                work: []//工作地点
            },
            rprules: {
                pName: [{ required: true, message: '请输入职位名称', trigger: 'change' }],
                pCategory: [{ required: true, message: '职位类别未选择', trigger: 'change' }],
                pDescribe: [{ required: true, message: '请输入职位描述', trigger: 'change' }],
                pKeyword: [{ required: true, message: '职位关键词未选择', trigger: 'change' }],
                pHighlights: [{ required: true, message: '职位亮点未选择', trigger: 'change' }],
                pNature: [{ required: true, message: '职位性质未选择', trigger: 'change' }],
                education: [{ required: true, message: '请选择学历要求', trigger: 'change' }],
                experience: [{ required: true, message: '请选择工作经验', trigger: 'change' }],
                salary: [{ required: true, message: '请选择薪资', trigger: 'change' }],
                minS: [{ required: true, message: '请选择薪资', trigger: 'change' }],
                maxS: [{ required: true, message: '请选择薪资', trigger: 'change' }],
                persons: [{ required: true, message: '招聘人数未填写', trigger: 'change' },
                {
                    validator: (rule: any, value: any, callback: any) => {
                        if (/^(?:[1-9]\d*)$/.test(value) == false) {
                            callback(new Error("请输入正整数"));
                        } else {
                            callback();
                        }
                    },
                    trigger: "change",
                },
                ],
                work: [{ required: true, message: '工作城市未选择', trigger: 'change' }]
            },
            activeName: 'first',
            categoryOp: [] as any,
            kwOp: [{
                value: 'HTML',
                label: 'HTML',
            },
            {
                value: 'CSS',
                label: 'CSS',
            }],
            natureList: [] as any,
            education: [] as any, //学历要求
            experience: [] as any, //工作经验
            pKeyword: [] as any, //关键词
            pHighlights: [] as any, //亮点
            minSop: [] as any, //最低薪资
            maxSop: [] as any, //最高薪资
            salaryop: [] as any, //薪资
            workop: [] as any, //工作城市
            workProps: {
                multiple: true
            },
            fromType: '' as any
        })
        state.salaryop = [];
        state.minSop = [];
        state.maxSop = [];
        for (var i = 12; i < 30; i++) {
            state.salaryop = [...state.salaryop, { value: i, label: i }]
        }
        for (var i = 1; i < 101; i++) {
            state.minSop = [...state.minSop, { value: i, label: i }];
            state.maxSop = [...state.maxSop, { value: i, label: i }];
        }
        loading = ElLoading.service({
            lock: true,
            text: '加载中...',
            background: 'rgba(0, 0, 0, 0.7)',
        })

        


        const initLoad = (jobInfo:any) => {
            //职位性质 学历要求 经验年限 工作地点  关键词 亮点 职位类别
            var doArr = ['job_nature', 'job_education', 'job_experience'].map(item => listDict({ type: item }))
            Promise.all([...doArr, listCity({ type: '2' }) , listJobKeyword({}), listJobLight({}) , listJobCategory({}) ]).then(res => {
                console.log('res==', res)
                state.natureList = res[0].data;
                state.rpRuleFrom.pNature = res[0].data.length > 0 ? res[0].data[0].name : '';
                state.education = res[1].data;
                state.experience = res[2].data;
                state.workop = res[3].data;
                state.pKeyword = res[4].data;
                state.pHighlights = res[5].data;
                state.categoryOp = res[6].data;
                loading!.close()
                if(jobInfo != null){
                    console.log('state.pHighlights',jobInfo.ext)
                    console.log('state.pHighlights',state.pHighlights)
                    state.rpRuleFrom.pName = jobInfo.name;
                    state.rpRuleFrom.pCategory = jobInfo.category;
                    state.rpRuleFrom.pDescribe = jobInfo.description;
                    state.rpRuleFrom.pKeyword = jobInfo.ext.keywordList.map((item:any)=>(item.custom?item.custom:item.keywordId));
                    state.rpRuleFrom.pHighlights = jobInfo.ext.lightList.map((item:any)=>(item.custom?item.custom:item.lightId))
                    state.rpRuleFrom.pNature = state.natureList.find((item:any)=>item.code==jobInfo.nature)?.name;
                    state.rpRuleFrom.education = state.education.find((item:any)=>item.code==jobInfo.education)?.code;
                    state.rpRuleFrom.experience = state.experience.find((item:any)=>item.code==jobInfo.experience)?.code;

                    state.rpRuleFrom.salary = jobInfo.salaryCount;
                    state.rpRuleFrom.minS = String(jobInfo.salaryMin/1000);
                    state.rpRuleFrom.maxS = String(jobInfo.salaryMax/1000);
                    state.rpRuleFrom.persons = jobInfo.jobCount;
                    state.rpRuleFrom.work = jobInfo.ext.cityList.map((item:any)=>([item.cityCode.substring(0,2)+'0000',item.cityCode]));
                }
            }).catch(f => {
                loading!.close()
            })
        }

        if (router.currentRoute.value.params.id) {
            state.fromType = router.currentRoute.value.params.type || '';
            getHunterJob({
                id: router.currentRoute.value.params.id
            }).then(res => {
                initLoad(res.data);
            })
        } else {
            initLoad(null);
        }

        const categoryChange = (e: any) => {
            console.log('categoryChange==', state.rpRuleFrom.pCategory)
        }

        const comit = async (formEl: any) => {
            console.log('state.rpRuleFrom',state.rpRuleFrom)
            
            if (!formEl) return
            await formEl.validate((valid: any, fields: any) => {
                if (valid) {
                    console.log('state.rpRuleFrom==', state.rpRuleFrom)
                    loading = ElLoading.service({
                        lock: true,
                        text: '提交资料中...',
                        background: 'rgba(0, 0, 0, 0.7)',
                    })
                    
                    addHunterJob({
                        name: state.rpRuleFrom.pName,
                        category: Array.isArray(state.rpRuleFrom.pCategory) ? state.rpRuleFrom.pCategory[state.rpRuleFrom.pCategory.length - 1] : state.rpRuleFrom.pCategory,
                        description: state.rpRuleFrom.pDescribe,
                        nature: state.natureList.find((item: any) => item.name == state.rpRuleFrom.pNature)?.code,
                        education: state.rpRuleFrom.education,
                        experience: state.rpRuleFrom.experience,
                        salaryMin: (Number(state.rpRuleFrom.minS) * 1000),
                        salaryMax: (Number(state.rpRuleFrom.maxS) * 1000),
                        salaryCount: state.rpRuleFrom.salary,
                        jobCount: state.rpRuleFrom.persons,
                        cityList: state.rpRuleFrom.work.map(item => ({ cityCode: item[1] })),
                        keywordList: state.rpRuleFrom.pKeyword.map(item => (isNaN(item) ? { keywordId: null, custom: item } : { keywordId: item, custom: '' })),
                        lightList: state.rpRuleFrom.pHighlights.map(item => (isNaN(item) ? { lightId: null, custom: item } : { lightId: item, custom: '' })),
                        state: 1,
                        id: state.fromType == 'edit' ? router.currentRoute.value.params.id : '',
                        city: state.workop.find((item:any)=>item.value==state.rpRuleFrom.work[0][0])?.children.find((item:any)=>item.value==state.rpRuleFrom.work[0][1]).label
                    }).then(res => {
                        loading!.close()
                        if (res.code == 0) {
                            ElMessage({
                                showClose: true,
                                message: state.fromType == 'edit'? '保存成功' : '发布成功',
                                type: 'success',
                            })
                            setTimeout(() => {
                                router.push({ path: '/position/index' })
                            }, 1000)
                        }
                    }).catch(f => {
                        loading!.close()
                    })
                }
            })
        }


        return {
            ...toRefs(state), categoryChange, ruleFormRef, comit
        }
    }
})
</script>

<style scoped>
@import "./index.css";
</style>